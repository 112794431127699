import { TokenValue } from '@frontend/types';
import { BigNumber } from 'bignumber.js';
import * as dn from 'dnum';

export const formatUtils = {
  crypto({
    value,
    decimals,
    dustDigits,
  }: Omit<TokenValue, 'formatted'> & { dustDigits?: number }) {
    const bn: dn.Dnum = [value, decimals];
    const sigZeros = Math.max(bn[1] - bn[0].toString().length, 0);
    const addedDigits = dustDigits ?? 2;
    return dn.format(bn, {
      digits: sigZeros + addedDigits,
      trailingZeros: false,
    });
  },
  cryptoFromString(valueStr: string) {
    const [integerPart, fractionalPart = ''] = valueStr.split('.');
    const decimals = fractionalPart.length;
    const combinedValue = integerPart + fractionalPart;
    const value = BigInt(combinedValue);
    return formatUtils.crypto({ value, decimals });
  },
  gas(value: string | number) {
    return BigNumber(BigNumber(value).dividedBy(1e18).toFormat(6)).toFixed();
  },
  percent(value: number) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
  },
};
